import '../style/steps.scss';

const STATUSES = {
    active: 'active',
    completed: 'completed',
    incomplete: 'incomplete',
}

function computeStatus(number, active) {
    if (number === active && number === 3) return STATUSES.completed;
    if (number === active) return STATUSES.active;
    if (number < active) return STATUSES.completed;
    return STATUSES.incomplete;
}

function Step({ status = STATUSES.incomplete, number = 1, text = '' }) {
    return (
        <div className={`step ${status}`}>
            <h3>Step {number}</h3>
            <h5>{text}</h5>
        </div>
    )
}

export default function Steps({ active = 1 }) {
    const steps = [
        { number: 1, text: 'Transaction Details' },
        { number: 2, text: 'Payment Confirmation' },
        { number: 3, text: 'Complete Transaction' },
    ];

    return (
        <div className="steps">
                {steps.map((step, i) => (
                    <Step key={i} number={step.number} status={computeStatus(step.number, active)} text={step.text} />
                ))}
        </div>
    )
}