/* eslint-disable jsx-a11y/anchor-is-valid */
import { Navbar, Nav, Button, Container, Row, Col, InputGroup, FormControl } from 'react-bootstrap'
import blueLogo from '../images/buywater_icon_blue.png';

import '../style/footer.scss';

export default function Footer() {
    return (
        <footer className="footer">
            <Container fluid="md">
                <Navbar sticky="bottom" className="bottom-nav">
                    <Navbar.Brand href="/">
                        <img
                            alt="BuyWater Logo"
                            src={blueLogo}
                            className="d-inline-block align-top"
                        />
                        <span className="nav-title">BuyWater</span>
                    </Navbar.Brand>
                    <Nav className="mr-auto"></Nav>
                    <Nav className="desktop">
                        <Navbar.Text className="action-text">
                            Ready to get started?
                        </Navbar.Text>
                        <Nav.Link>
                            <Button className="nav-btn" variant="primary">BUY WATER</Button>
                        </Nav.Link>
                    </Nav>
                </Navbar>
                <Row className="desktop cols">
                    <Col>
                        <h4>Subscibe to our Newsletter</h4>
                        <div className="newsletter-input">
                            <InputGroup className="mb-3">
                                <FormControl
                                    placeholder="Email Address"
                                />
                                <InputGroup.Append>
                                    <InputGroup.Text className="newsletter-append">
                                        <i className="fas fa-chevron-right"></i>
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>
                    </Col>
                    <Col>
                        <h4>Hot Links</h4>
                        <p><a href="#">Buy Water</a></p>
                        <p><a href="#">Blog</a></p>
                        <p><a href="#">Terms & Conditions</a></p>
                        <p><a href="#">Privacy Policy</a></p>
                    </Col>
                    <Col>
                        <h4>About</h4>
                        <p><a href="#">Our Story</a></p>
                        <p><a href="#">Team</a></p>
                        <p><a href="#">Careers</a></p>
                    </Col>
                    <Col>
                        <h4>Help</h4>
                        <p><a href="#">FAQs</a></p>
                        <p><a href="#">Contact Us</a></p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}