import { useState } from 'react';
import { useHistory } from "react-router-dom";

import classnames from 'classnames';
import { Form, Button } from 'react-bootstrap';

import '../style/home.scss';

import HomeNav from './HomeNav';

import { validatePhone, validateMeter, callApi, setAppData, getAppData } from '../utils';

export default function Home() {
    const userData = getAppData('user') || {};

    const history = useHistory();
    const [phone, setPhone] = useState(userData.phone || '');
    const [meter, setMeter] = useState(userData.meter || '');
    const [loading, setLoading] = useState(false);
    const [isPhoneValid, setPhoneValid] = useState(true);
    const [isMeterValid, setMeterValid] = useState(true);

    async function submitForm(e) {
        e.preventDefault();
        if (!isPhoneValid) return window.alert('Invalid Phone Number!');
        if (!isMeterValid) return window.alert('Invalid Meter Number!');

        setAppData({ user: { meter, phone } });
        setLoading(true);
        const data = await callApi('/order', { phone, meter }, 'POST')
            .catch(e => ({ ...e, success: false }));

        setLoading(false);
        if (!data.success) return window.alert(data.message);

        setAppData({ transaction: data.data });
        history.push('/app/order');
    }

    return (
        <div className="homepage">
            <div className="home-hero">
                <HomeNav />
                <div className="container-sm">
                    <div className="home-content row">
                        <div className="col-md-6">
                            <Form className="home-form-container" onSubmit={submitForm}>
                                <h1>Pay Water Bills hassle free from the comfort of your home</h1>
                                <p>Please provide your Phone Number & Smart Meter Number</p>
                                <Form.Control
                                    type="tel"
                                    placeholder="Phone Number"
                                    value={phone}
                                    onChange={e => setPhone(e.target.value)}
                                    onBlur={() => setPhoneValid(validatePhone(phone))}
                                    className={classnames({ error: !isPhoneValid })}
                                />

                                <Form.Control
                                    type="tel"
                                    placeholder="Meter Number"
                                    value={meter}
                                    onChange={e => setMeter(e.target.value)}
                                    onBlur={() => setMeterValid(validateMeter(meter))}
                                    className={classnames({ error: !isMeterValid })}
                                />

                                <Button type="submit" variant="primary" size="lg" disabled={loading} block>
                                    {loading ? 'PLEASE WAIT...' : 'BUY WATER'}
                                    &nbsp;&rarr;
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}