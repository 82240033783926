import classnames from 'classnames';
import { Link } from 'react-router-dom';

import '../style/sidebar.scss';

const LINKS = [
    { name: 'Home', path: '/', icon: 'tint' },
    { name: 'Transaction History', path: '/app/transactions', icon: 'credit-card' },
    { name: 'My Meters', path: '#', icon: 'address-book' },
    { name: 'Reports', path: '#', icon: 'chart-area' },
    { name: 'View Profile', path: '#', icon: 'user' },
];

function SidebarLink({ active = false, name, path, icon }) {
    return (
        <div className="sidebar-link">
            <Link className={classnames({ active })} to={path}>
                <i className={`fas fa-${icon}`}></i>
                &nbsp;&nbsp;
                {name}
            </Link>
        </div>
    )
}

export default function Sidebar() {
    return (
        <div className="sidebar desktop">
            {
                LINKS.map(link => (
                    <SidebarLink
                        key={link.name}
                        {...link}
                        active={Boolean(window.location.pathname.match(link.path))}
                    />
                ))
            }
        </div>
    )
}