import {
    Switch,
    Route,
} from "react-router-dom";

import HomeNav from './HomeNav';
import StartPayment from './StartPayment';
import ConfirmPayment from './ConfirmPayment';
import CompletePayment from './CompletePayment';
import Transactions from './Transactions';

export default function Main() {
    return (
        <div className="main mb-5">
            <HomeNav main={true} />

            <div className="container-sm mb-5">
                <Switch>
                    <Route path="/app/order">
                        <StartPayment />
                    </Route>
                    <Route path="/app/confirm">
                        <ConfirmPayment />
                    </Route>
                    <Route path="/app/complete">
                        <CompletePayment />
                    </Route>
                    <Route path="/app/transactions">
                        <Transactions />
                    </Route>
                </Switch>
            </div>
        </div>
    )
}