import '../style/spinner.scss';

export default function Spinner({ size = 100, className }) {
    return (
        <div className={`${className} spinner d-flex justify-content-center align-items-center`}>
            <div className="sk-chase" style={{ height: size, width: size }} >
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
            </div>
        </div>
    )
}