import '../style/transaction_data.scss';

export default function TransactionData({ title, value, isAmount = false, currency = 'NGN', child }) {
    return (
        <div className="transaction-data">
            <h5>
                <span className="title">{title}:</span>
                <span className="value">&nbsp;&nbsp;&nbsp;{value}</span>
                {isAmount ? <span className="currency">&nbsp;{currency}&nbsp;</span> : null}
                {child ? child : null}
            </h5>
        </div>
    )
}