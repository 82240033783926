import {Row, Col} from 'react-bootstrap'

import '../style/transactions.scss';

import Sidebar from './Sidebar';

export default function Transactions() {
    return (
        <div className="transactions">
            <div className="desktop">
                <Row>
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        Transactions
                    </Col>
                </Row>
            </div>
        </div>
    )
}