import { InputGroup, FormControl, Button } from 'react-bootstrap';

import { formatToken } from '../utils';

import '../style/token.scss';

export default function TokenBox({ token = '' }) {
    return (
        <div className="token-box">
            <InputGroup className="mb-3">
                <FormControl
                    disabled
                    value={formatToken(token)}
                />
                <InputGroup.Append>
                    <Button variant="outline-secondary"><i className="far fa-copy"></i></Button>
                </InputGroup.Append>
            </InputGroup>
        </div>
    )
}