/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { Button } from 'react-bootstrap';

import Steps from './Steps';
import Container from './Container';
import Card from './Card';
import TokenBox from './TokenBox';
import TransactionData from './TransactionData';

import '../style/complete_payment.scss';

import { getAppData, commaNumber } from '../utils';

export default function ConfirmPayment() {
    const history = useHistory();
    const [data, setData] = useState({});

    useEffect(() => {
        const { order, vend, meter } = getAppData('vend');
        if (!order || !vend || !vend.token) {
            return history.push('/');
        }

        setData({
            meter: meter.number,
            name: meter.name,
            token: vend.token,
            amount: commaNumber(Number(order.amount)),
            vendAmount: commaNumber(Number(vend.amount)),
            reference: order.id,
            volume: vend.volume,
            debt: Number(vend.debt) || 0,
            vat: commaNumber(Number(vend.vat)),
            date: new Date(order.createdAt).toDateString(),
        })
    }, []);

    return (
        <div className="complete-payment">
            <Steps active={3} />
            <Container>
                <Card>
                    <div className="complete-payment-box">
                        <div className="top">
                            <h2>Transaction Complete!🎉🎉🎉</h2>
                            <p>Here are the details of your transaction</p>
                        </div>

                        <h5>Meter Token</h5>
                        <TokenBox token={data.token} />
                        <TransactionData title="Amount Paid" value={data.amount} isAmount={true} />
                        <TransactionData title="Volume" value={data.volume} child={<span>&nbsp;m<sup>3</sup></span>} />
                        <TransactionData title="Reference" value={data.reference} />
                        <TransactionData title="VAT" value={data.vat} isAmount={true} />
                        {data.debt ? <TransactionData title="Debt" value={data.debt} isAmount={true} /> : null}
                        <TransactionData title="Meter" value={data.meter} />
                        <TransactionData title="Name" value={data.name} />
                        <TransactionData title="Date" value={data.date} />
                        <hr />
                        <Button type="link" href="/" variant="primary" size="lg" className="mb-3 home-btn" block>
                            GO HOME
                        </Button>
                    </div>
                </Card>
            </Container>
        </div>
    );
}
