import { Navbar, Nav, Button } from 'react-bootstrap'

import logo from '../images/buywater-logo-white-min.png';
import blueLogo from '../images/buywater-logo-blue-min.png';

import '../style/nav.scss';

export default function HomeNav({ main = false }) {
    return (
        <div className={main ? 'main-nav' : ''}>
            <div className="home-nav">
                <div className="container-sm">
                    <Navbar className="nav-body" collapseOnSelect expand="md">
                        <Navbar.Brand href="/">
                            <img
                                alt="BuyWater Logo"
                                src={main ? blueLogo : logo}
                                className="nav-logo d-inline-block align-top"
                            />
                            {/* <span className="nav-title"></span> */}
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="mr-auto"></Nav>
                            <Nav>
                                <Nav.Link>FAQ</Nav.Link>
                                <Nav.Link>About Us</Nav.Link>
                                <Nav.Link>Blog</Nav.Link>
                                <Nav.Link>
                                    <Button className="nav-btn" variant="outline-light">Contact Us</Button>
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </div>
        </div>

    )
}