/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";

import '../style/confirm_payment.scss';

import Steps from './Steps';
import Container from './Container';
import Card from './Card';
import Spinner from './Spinner';

import { getAppData,  callApi, setAppData } from '../utils';

export default function ConfirmPayment() {
    const history = useHistory();

    useEffect(() => {
        const appData = getAppData();
        if (!appData || !appData.transaction || !appData.transaction.order) {
            return history.push('/app/order');
        }

        callVendApi(appData.transaction.order);
    }, []);

    async function callVendApi(id) {
        debugger;
        const data = await callApi(`/vend/${id}`).catch(e => ({ ...e, success: false }));
        if (!data.success) {
            window.alert(data.message);
            return history.push('/app/order');
        }

        setAppData({ vend: data.data });
        history.push('/app/complete');
    }
    return (
        <div className="confirm-payment">
            <Steps active={2} />
            <Container>
                <Card>
                    <div className="confirm-payment-card">
                        <Spinner />
                        <div className="bottom-text align-items-end">
                            <div>
                                <h2>Generating Token</h2>
                                <p>Please Wait...</p>
                            </div>
                        </div>
                    </div>
                </Card>
            </Container>
        </div>
    )
}