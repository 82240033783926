/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import classnames from 'classnames';
import { Form, Button, InputGroup, FormControl } from 'react-bootstrap'

import '../style/start_payment.scss';

import Steps from './Steps';
import Container from './Container';
import Card from './Card';

import { getAppData, convertToNumber, commaNumber, validateAmount, } from '../utils';

export default function StartPayment() {
    const history = useHistory();
    const [meter, setMeter] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [channel, setChannel] = useState('PAYSTACK');
    const [key, setKey] = useState('');
    const [order, setOrder] = useState('');
    const [amount, setAmount] = useState('');
    const [displayAmount, setDisplayAmount] = useState('');
    const [ready, setReady] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const data = getAppData('transaction');
        if (!data || !data.order) {
            return history.push('/');
        }

        setMeter(data.meter.number);
        setName(data.meter.name);
        setPhone(data.phone);
        setOrder(data.order);
        setChannel(data.paymentChannel);
        setKey('pk_live_0d7420e98dcf137913857e84e8cb4a53e6c8658c');
    }, []);

    function handleAmountInput(e) {
        const amount = convertToNumber(e.target.value);
        setDisplayAmount(amount)
        setAmount(amount);
        setReady(validateAmount(amount));
    }

    function handleAmountReady() {
        const amt = commaNumber(amount);
        setDisplayAmount(amt)
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setDisplayAmount(amount);
        debugger;
        if (!amount || !ready) return window.alert('Invalid Amount!');

        if(channel === 'PAYSTACK') {
            // Load Paystack
            const paystackHandler = window.PaystackPop.setup({
                key: key,
                email: `${phone}@buywater.ng`,
                amount: convertToNumber(amount) * 100,
                ref: order,
                onClose() {
                    return setLoading(false);
                },
                callback(response) {
                    console.log(response);
                    return history.push('/app/confirm');
                }
            });
            paystackHandler.openIframe();
        }
    }

    return (
        <div className="start-payment">
            <Steps active={1} />
            <Container>
                <Card>
                    <div className="payment-info-box">
                        <h5>Meter Number</h5>
                        <h2>{meter}</h2>

                        <h5>Meter Name</h5>
                        <h2>{name}</h2>

                        <h5>How much do you want to pay?</h5>
                        <Form onSubmit={handleSubmit}>
                            <InputGroup className="mb-3 amount-input-box">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>&#8358;</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    className={classnames({ 'amount-input': true, error: !ready })}
                                    placeholder="Enter Amount"
                                    type="tel"
                                    value={displayAmount}
                                    onFocus={() => setDisplayAmount(amount)}
                                    onChange={handleAmountInput}
                                    onBlur={handleAmountReady}
                                />
                            </InputGroup>
                            <Button type="submit" disabled={loading} variant="primary" size="lg" className="mb-3" block>
                                {loading ? 'INITIATING PAYMENT...' : 'CONTINUE TO PAYMENT'}
                                &nbsp;&rarr;
                            </Button>
                        </Form>
                        <Button onClick={() => history.push('/')} variant="outline-primary" size="lg" block>
                            &nbsp;&larr;CHANGE METER NUMBER
                        </Button>
                    </div>
                </Card>
            </Container>
        </div>
    )
}