
export function formatToken(str) {
    if (!str) return '';
    str = stripToken(str);
    let totalSplit = Math.floor(str.length / 4);
    let start = 0;
    let end = 4;
    let output = '';
    for (let i = 0; i < totalSplit; i++) {
        output += str.slice(start, end);
        output += i === totalSplit - 1 ? '' : '-';
        start += 4;
        end += 4;
    }
    return output.trim('-');
};

function stripToken(str) {
    return String(str).replace(/-|\s+/gi, '');
};

const BASE_URL = process.env.REACT_APP_API_URL || 'https://buywater.nw.r.appspot.com';
export function callApi(url, data, method = 'GET') {
    return new Promise(function (resolve, reject) {
        method = method.toUpperCase();
        let options = {
            method,
            mode: 'cors',
            redirect: 'follow',
            compress: true,
            // credentials: 'include',
            Accept: 'application/json',
        };
        if (method === 'POST') {
            options.body = JSON.stringify(data);
            options.headers = {};
            options.headers['Content-Type'] = 'application/json';
        } else {
            const query = new URLSearchParams(data).toString();
            url += query ? `?${query}` : '';
        }
        fetch(url.match('https') ? url : `${BASE_URL}${url}`, options)
            .then(res => {
                if (res.ok) {
                    return res.json();
                }
                throw res;
            })
            .then(data => resolve(data))
            .catch(err => {
                if (err.json) {
                    err
                        .json()
                        .then(reject)
                        .catch(reject);
                } else {
                    reject(err);
                }
            });
    });
};

export function validatePhone(n = '') {
    let firstChar;
    let number;
    let pattern = /^([0]{1})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7,8})$/g;

    if (!n || n.length < 5) return false;

    if (typeof n === 'number') {
        // numbers never begin with 0, force this to become a string
        number = '0' + n;
    } else if (typeof n === 'string') {
        firstChar = n.substring(0, 1);

        // user may supply 0 before the number or not
        // e.g 0703 or 703 (two types of people ¯\_(ツ)_/¯)
        // either way supply missing leading 0
        number = firstChar === '0' ? n : '0' + n;
    } else {
        return false;
    }

    // remove all non-numeric characters
    return pattern.test(number.replace(/\D/g, ''));
};

export function validateMeter(str) {
    return String(str).replace(/\D/g, '').length > 6;
}

export function validateAmount(amount) {
    const amt = convertToNumber(amount);
    return amt && Number.isSafeInteger(amt) && amt >= 200;
}

export function convertToNumber(str) {
    return Number(String(str).replace(/\D/g, ''))
}

export function commaNumber(str) {
    return Number(str).toLocaleString('en-US');
}

export function setAppData(obj = {}) {
    window.localStorage.appdata = JSON.stringify({
        ...(JSON.parse(window.localStorage.appdata || '{}')),
        ...obj,
    })
    return true;
}
window.localStorage.appdata = window.localStorage.appdata || '{}';

export function getAppData(key = '') {
    let data = JSON.parse(window.localStorage.appdata);
    if(key) {
        key.split('.').forEach((subKey) => {
            data = data[subKey];
        })
    }
    return data;
}